import React, { Component } from "react";
import styled from "styled-components";
import tw from "twin.macro";
// import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";
import axios from "axios";
import dateFormat from "dateformat";
import { ContentWithPaddingXl, Container } from "components/misc/Layouts";

// import { isCompositeComponent } from "react-dom/test-utils";
// import ThreeColSimple from "components/features/ThreeColSimple";

import Table from "./table"


// const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;



export default class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchbox_query: "",
      searchOptions: [],
      showTable: false,
      products: [],

    }
    this.handleSearchBoxChange = this.handleSearchBoxChange.bind(this);
  }

  handleSearchBoxChange(e) {
    this.setState({ searchbox_query: e.target.value })
    this.handleUpdateSearchOptions(e.target.value)
    e.preventDefault();
  }

  handleUpdateSearchOptions = (query) => {
    const url = `https://f06i9ct5li.execute-api.us-east-1.amazonaws.com/prod/api/v1/productname?search=${query}`
    axios.get(url)
      .then((response) => {
        this.setState({ searchOptions: response.data });
      });
  }

  handleSearchClick = (productname) => {
    this.setState({ searchbox_query: productname })
    this.setState({ searchOptions: [] })
    this.setState({ showTable: true }, this.handleUpdateProducts(productname))
  }

  handleUpdateProducts = (productname) => {
    const url = `https://f06i9ct5li.execute-api.us-east-1.amazonaws.com/prod/api/v1/samples?ProductName=${productname}`
    axios.get(url)
      .then((response) => {
        const products_with_new_date = response.data.map( product => {
          product.Collection_Date = dateFormat(product.Collection_Date,"mm/dd/yyyy")
          product.SamplesCreationDate = dateFormat(product.SamplesCreationDate,"mm/dd/yyyy")
          return product
        })
        response.data = products_with_new_date
        this.setState({ products: response.data });
        // console.log(response.data)   
      });
  }

  render() {
    return (
      <div id="inventory">
        <Container>
          <ContentWithPaddingXl>
          <ThreeColumnContainer>
            <Heading>
              Our <span tw="text-primary-500">Inventory</span>
            </Heading>
            <form onSubmit={this.handleSubmit}>
              <label>
                <input placeholder="Search our Inventory" name="searchboxtext" type="text" value={this.state.searchbox_query} onChange={this.handleSearchBoxChange} />
              </label>
              {this.state.searchOptions.map(product => (
                <ul key={product._id}>
                  <li key={product._id} onClick={this.handleSearchClick.bind(this, product.name)}>{product.name}</li>
                </ul>
              ))}
            </form>
            <Table state={this.state} />
          </ThreeColumnContainer>
          <DecoratorBlob />
          
          </ContentWithPaddingXl>

        </Container>
      </div>
    );
  }
}


