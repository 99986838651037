import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
// import { numberFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

const { ExportCSVButton } = CSVExport;

const selectRow = {
  mode: 'checkbox',
};


export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          dataField: "Sample_ID",
          text: "Sample ID",
          sort: true,
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "155px", textAlign: "center" };
          },
        },
        {
          dataField: "AgeFld",
          text: "Age",
          sort: true,
          align: 'center',
        
          headerStyle: (colum, colIndex) => {
            return {
              width: "50px",
              textAlign: "center",
            };
          },
          // filter: numberFilter()
        },
        {
          dataField: "Gender",
          text: "Gender",
          sort: true,
          
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return {
              width: "85px",
              textAlign: "center",
            };
          },
        },
        {
          dataField: "ProductName",
          text: "Product Name",
          sort: true,
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return {
              width: "160px",
              textAlign: "center",
            };
          },
        },
        {
          dataField: "ValueFld",
          text: "Value",
          sort: true,
          sortFunc: (a, b, order, dataField, rowA, rowB) => {
            if (order === 'asc') {
              return Number(b) - Number(a);
            }
            return Number(a) - Number(b); // desc
            }, 

          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "75px", textAlign: "center", overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' };
          },
          
        },
        {
          dataField: "Unit_of_Measure",
          text: "Units",
          sort: true,
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return {
              width: "65px",
              textAlign: "center",
              "whiteSpace": "nowrap",
            };
          },
        },
        {
          dataField: "SampleType",
          text: "Sample Type",
          sort: true,
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "90px", textAlign: "center" };
          },
        },
        {
          dataField: "Collection_Date",
          text: "Collection Date",
          sort: true,
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "115px", textAlign: "center" };
          },
        },
        {
          dataField: "TotalCurrentAmount",
          text: "Volume (mLs)",
          sort: true,
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "75px", textAlign: "center" };
          },
        },
        {
          dataField: "Thaw_Freeze",
          text: "# of Thaw-Freeze",
          sort: true,
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "95px", textAlign: "center" };
          },
        },
        {
          dataField: "Instrument",
          text: "Instrument",
          sort: true,
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "180px", textAlign: "center" };
          },
        },
      ]
    }
  }


  render() {

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        &nbsp; Showing {from} to {to} of {size} Results
      </span>
    );

    const options = {
      paginationSize: 6,
      pageStartIndex: 0,
      // alwaysShowAllBtns: true, // Always show next and previous button
      // withFirstAndLast: false, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '5', value: 5
      }, {
        text: '10', value: 10
      }, {
        text: '30', value: 30
      }, {
        text: '50', value: 50
      }, {
        text: '100', value: 100
      }, {
        text: 'All', value: this.props.state.products.length
      }] // A numeric array is also available. the purpose of above example is custom the text
    };

    return (
      <div>
        {this.props.state.showTable &&
          <ToolkitProvider
            keyField="_id"
            data={this.props.state.products}
            columns={this.state.columns}
            search
            exportCSV={{
              fileName: 'ComplexAntibodies-ProductSelection.csv',
              separator: ',',
              ignoreHeader: false,
              noAutoBOM: false,
              onlyExportSelection: true,
              exportAll: false
            }}
          >
            {(props) => (
              <div>
                <ExportCSVButton {...props.csvProps} 
                  style={{"color": "white", 
                          "border-style": "solid", 
                          "backgroundColor": "rgb(58, 12, 207)", 
                          "padding": "12px 20px",
                          "margin-bottom": "15px",
                          "box-sizing": "border-box",
                          "border": "2px solid rgb(58, 12, 207)",
                          "border-radius": "20px",
                          }}>
                  Export Selected
                </ExportCSVButton>
                <text><br/>
                *Indicates some degree of hemolysis <br/>
                **Indicates some degree of icteremia
                </text>
                
                <BootstrapTable {...props.baseProps}
                  pagination={paginationFactory(options)}
                  selectRow={selectRow}
                />
              </div>
            )
            }
          </ToolkitProvider>
        }


      </div>
    )
  }
}
